import { useTranslation } from "react-i18next";

export class ValidationService {
  static requiredValidator() {
    const { t, i18n } = useTranslation(["Validation"]);

    return {
      value: true,
      message: t("Validation:This field is required."),
    };
  }
  static emailValidator() {
    const { t, i18n } = useTranslation(["Validation"]);

    return {
      value: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
      message: t("Validation:Please enter a valid email address."),
    };
  }
  static positiveNumber(value: any, t: any) {
    // const { t, i18n } = useTranslation(["Validation"]);

    if (value < 1) {
      return t("Validation:Please enter any positive number");
    } else {
      return;
    }
  }
  static dateTimeValidatorMoreThanCurrentTime(value: any) {
    const { t, i18n } = useTranslation(["Validation"]);

    console.log(value, "valuevaluevaluevaluevaluevalue");
    if (value) {
      return t("Validation:The passwords do not match");
    } else {
      return t("Validation:The passwords do not match");
    }
  }
  static passwordValidator() {
    const { t, i18n } = useTranslation(["Validation"]);

    return {
      value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&.*-]).{8,}$/,
      message: t(
        "Validation:Password must contain at least 8 characters, one lowercase letter, one uppercase letter, one numeric digit, and one special character"
      ),
    };
  }
  static domainValidator() {
    const { t, i18n } = useTranslation(["Validation"]);

    return {
      //eslint-disable-next-line
      value: /^(?!(https:\/\/|http:\/\/|mailto:|smtp:|ftp:\/\/|ftps:\/\/))(((([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,86}[a-zA-Z0-9]))\.(([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,73}[a-zA-Z0-9]))\.(([a-zA-Z0-9]{2,12}\.[a-zA-Z0-9]{2,12})|([a-zA-Z0-9]{2,25})))|((([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,162}[a-zA-Z0-9]))\.(([a-zA-Z0-9]{2,12}\.[a-zA-Z0-9]{2,12})|([a-zA-Z0-9]{2,25}))))$/,
      message: t("Validation:must be a valid hostname"),
    };
  }
  static urlValidator() {
    const { t, i18n } = useTranslation(["Validation"]);

    return {
      value: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,}(:[0-9]{1,5})?(\/.*)?$/,
      message: t("Validation:must be a valid link"),
    };
  }
}
